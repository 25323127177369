const DEFAULT_COMMENT_LENGTH = 255

type PageableResponse<T> = {
  totalCount: number
  pageCount: number
  items: T[]
}

type SelectOption<T> = {
  title: string
  value: T
}

enum SortDirection {
  ASC,
  DESC,
  NONE
}

enum QuoteTabs {
  REQUEST,
  QUOTE,
  ORDER,
  DOCUMENTS
}

type ViewToggle<T> = {
  name: T
  icon: string
  label?: string
  showOnMobile: boolean
  restriction: () => boolean
  count?: {
    loadFunction: () => Promise<void | boolean>
    getter: () => boolean
  }
}

type VForm = {
  validate: () => boolean
  resetValidation: () => boolean
  reset: () => void
}

type VMenu = {
  isActive: boolean
}

type VAutoCompleteGroupDivider = { divider: true }

const PAGE_SIZE = 24

type CommentType = 'ORDER' | 'QUOTE'

enum Language {
  de = 'de',
  en = 'en',
  fr = 'fr',
  it = 'it',
  pl = 'pl',
  nl = 'nl',
  es = 'es'
}

const FALLBACK_LOCALE = 'en'
const supportedLocales = Object.keys(Language)
type Locale = (typeof supportedLocales)[number]

type KeyValue = { key: string; value: boolean }

type DataTableCompareFunction<T> = (a: T, b: T) => number
type DataTableHeader<T> = {
  title?: string
  key?: string
  value?: string
  width?: string
  align?: 'center' | 'end' | 'start'
  sortable?: boolean
  sort?: DataTableCompareFunction<T>
  headerProps?: {
    readonly [x: string]: unknown
  }
  cellProps?: {
    readonly [x: string]: unknown
  }
}

interface InternalItem<T> {
  value: T
  raw: T
}
export {
  CommentType,
  DataTableHeader,
  DEFAULT_COMMENT_LENGTH,
  FALLBACK_LOCALE,
  InternalItem,
  KeyValue,
  Language,
  Locale,
  PAGE_SIZE,
  PageableResponse,
  QuoteTabs,
  SelectOption,
  SortDirection,
  supportedLocales,
  VAutoCompleteGroupDivider,
  VForm,
  ViewToggle,
  VMenu
}
