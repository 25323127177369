<template>
  <v-card
    class="d-flex flex-column align-center text-center error-page pa-3"
    :color="transparentBackground ? 'rgba(0,0,0,0)' : undefined"
    :flat="transparentBackground"
  >
    <div class="container">
      <v-img ref="illustration" :src="errorImageSource" max-height="300" />
      <div ref="headline" class="text-h3 mt-10">
        {{ errorHeadline }}
      </div>
      <div class="mt-3 mb-6">
        <span v-if="message || code" ref="errorMessage" class="d-block">
          {{ errorMessage }}
        </span>
        <span
          v-if="isQuotesPortal && (code == 403 || code == 404)"
          ref="sorryMessage"
          class="d-block"
        >
          {{ $t('errorPageQuoteNotAvailableSorry') }}
        </span>
      </div>
      <sf-button
        v-if="code !== 403"
        ref="goStartButton"
        :to="{ name: 'StartPage' }"
        class="flex-grow-0 mb-1"
        block
        size="large"
      >
        {{ $t('errorPageStart') }}
      </sf-button>
    </div>
  </v-card>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import { isQuotesPortal, useTitle } from '@/helpers'
import { getErrorHeadline, getErrorImageSource, getErrorMessage } from '@/modules/error/helpers'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ErrorComponent',
  components: { SfButton },
  props: {
    code: {
      type: Number,
      default: undefined
    },
    message: {
      type: String,
      default: undefined
    },
    transparentBackground: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n()
    const pageTitle = computed(() =>
      props.code == 404 || props.code == 403 ? `${props.message}` : `${t('errorPageHeadline')}`
    )
    useTitle(pageTitle)
    const errorImageSource = getErrorImageSource(isQuotesPortal.value, props.code!)
    const errorHeadline = getErrorHeadline(props.code!)
    const errorMessage = getErrorMessage(props.code!, props.message!)
    return {
      errorImageSource,
      errorHeadline,
      errorMessage,
      isQuotesPortal
    }
  }
})
</script>

<style lang="scss" scoped>
.error-page {
  .container {
    max-width: 500px;
  }
}
</style>
