<template>
  <v-dialog v-model="dialog" scrollable :width="500" @click:outside="cancel" @keydown.esc="cancel">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-title ref="title">
        <sf-heading>{{ $t('contactData.contactData') }}</sf-heading>
      </v-card-title>
      <v-card-text class="pt-1">
        <v-form ref="form" v-model="valid" lazy-validation>
          <sf-text-field
            ref="first-name"
            v-model="contactData.firstName"
            :label="$t('contactData.firstName') + ' *'"
            :rules="[rules.requiredRule]"
            autocomplete="given-name"
          />
          <sf-text-field
            ref="last-name"
            v-model="contactData.lastName"
            :label="$t('contactData.lastName') + ' *'"
            :rules="[rules.requiredRule]"
            autocomplete="family-name"
          />
          <sf-text-field
            ref="phone"
            v-model="contactData.phone"
            :label="$t('user.phone')"
            :rules="[rules.phoneRule]"
            autocomplete="tel"
            @update:model-value="parsePhoneNumber($event, 'phone')"
          />
          <sf-text-field
            ref="phoneMobile"
            v-model="contactData.phoneMobile"
            :label="$t('user.phoneMobile')"
            :rules="[rules.phoneRule]"
            autocomplete="tel"
            @update:model-value="parsePhoneNumber($event, 'phoneMobile')"
          />
          <sf-text-field
            ref="email"
            v-model="contactData.email"
            :label="$t('contactData.email') + ' *'"
            :rules="[rules.mailRule, rules.requiredRule]"
            :disabled="disableEmailInput"
            autocomplete="email"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <sf-text-button
          v-if="showDeleteButton"
          ref="delete-contact-data-button"
          color="error"
          @click="$emit('delete')"
        >
          {{ $t('deleteContactData') }}
        </sf-text-button>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="$emit('cancel')">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button ref="save-contact-data-button" :disabled="!valid" @click="saveContactData">
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import rules from '@/rules'
import { VForm } from '@/types'
import { defineComponent, PropType, ref, watch } from 'vue'
import * as phoneUtil from 'libphonenumber-js'
import { clone } from 'lodash-es'
import { ContactData } from '@/generatedTypes'
import SfTextField from '@/components/input/SfTextField.vue'

export default defineComponent({
  name: 'ContactDataForm',
  components: { SfHeading, SfTextButton, SfTextField },
  props: {
    contact: {
      type: Object as PropType<ContactData>,
      required: true
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    disableEmailInput: {
      type: Boolean,
      default: false
    }
  },
  emits: ['delete', 'save', 'cancel'],
  setup(props, { emit }) {
    const dialog = ref(false)
    const form = ref<VForm>()
    const valid = ref(false)
    const contactData = ref(clone(props.contact))

    const resetForm = () => {
      if (form.value) {
        form.value.resetValidation()
      }
    }

    const cancel = () => {
      emit('cancel')
      dialog.value = false
    }

    const saveContactData = () => {
      if (form.value?.validate()) {
        emit('save', {
          ...contactData.value
        })
      }
    }

    watch(dialog, (newValue) => {
      if (newValue === true) {
        contactData.value = clone(props.contact)
        resetForm()
      }
    })

    const parsePhoneNumber = (number: string, type: 'phone' | 'phoneMobile') => {
      if (number.charAt(0) === '+') {
        try {
          contactData.value[type] = phoneUtil.parsePhoneNumber(number).formatInternational()
        } catch (error) {
          contactData.value[type] = number
        }
      }
    }

    return {
      dialog,
      valid,
      rules,
      form,
      contactData,
      cancel,
      saveContactData,
      parsePhoneNumber
    }
  }
})
</script>
