<template>
  <v-dialog v-model="dialog" :width="800" scrollable attach @keydown.esc="cancel">
    <template #activator="{ props }">
      <component
        :is="bigButton ? 'SfLargeButton' : 'SfTextButton'"
        ref="punchout-button"
        class="pw-punchout-button"
        :block="$vuetify.display.xs"
        :loading="loading"
        :disabled="disabled"
        v-bind="props"
        @click="showPunchoutPopup"
      >
        {{ $t('punchout.backToProcurement') }}
      </component>
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('punchout.backToProcurement') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <loading-spinner v-if="loading" class="fill-height" flat />
        <v-data-table
          v-else
          :headers="headers"
          :items="punchoutForm.items"
          :row-props="getRowProps"
          :items-per-page="-1"
          :no-data-text="$t('noDataAvailable')"
        >
          <template #bottom></template>
          <template #[`item.pos`]="{ item }">
            {{ punchoutForm.items.indexOf(item) + 1 }}
          </template>
          <template #[`item.priceForOne`]="{ item }">
            {{ formatPurchasePrice(item.priceForOne) }}
          </template>
          <template #[`item.sum`]="{ item }">
            {{ formatPurchasePrice(item.quantity * item.priceForOne) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <punchout-form :form="punchoutForm" :is-quote="quoteId !== undefined" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { formatPurchasePrice } from '@/modules/product/helpers'
import PunchoutForm from '@/modules/punchout/components/PunchoutForm.vue'
import usePunchout from '@/modules/punchout/usePunchout'
import useQuote from '@/modules/quote/useQuote'
import { PunchoutEvent } from '@/modules/tracking/events/punchout'
import { trackEvent } from '@/modules/tracking/useTracking'
import { DataTableHeader } from '@/types'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'PunchoutDialog',
  components: {
    PunchoutForm,
    LoadingSpinner,
    SfHeading,
    SfLargeButton,
    SfTextButton
  },
  props: {
    supplierCartId: {
      type: String,
      default: undefined
    },
    quoteId: {
      type: String,
      default: undefined
    },
    bigButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n()

    const dialog = ref(false)
    const headers: DataTableHeader<unknown>[] = [
      {
        title: t('punchout.table.position').toString(),
        value: 'pos',
        align: 'start'
      },
      {
        title: t('punchout.table.title').toString(),
        value: 'displayName'
      },
      {
        title: t('punchout.table.quantity').toString(),
        value: 'quantity',
        align: 'end'
      },
      {
        title: t('punchout.table.price').toString(),
        value: 'priceForOne',
        align: 'end'
      },
      {
        title: t('punchout.table.sum').toString(),
        value: 'sum',
        align: 'end'
      }
    ]
    const { punchoutForm, loading } = storeToRefs(usePunchout())

    const showPunchoutPopup = () => {
      if (props.quoteId) {
        // close event stream to prevent closing dialog when quote is
        // updating because of loading punchout form (quote is set to Won)
        useQuote().closeQuoteUpdateStream()
        usePunchout().loadPunchoutQuoteForm(props.quoteId)
      } else {
        usePunchout().loadPunchoutCartForm(props.supplierCartId)
      }
      dialog.value = true
      trackEvent(PunchoutEvent.OpenBackToSystem)
    }

    const cancel = () => {
      usePunchout().$reset()
      dialog.value = false
      if (props.quoteId) {
        useQuote().openQuoteUpdateStream(props.quoteId, false)
      }
    }

    const getRowProps = () => {
      return { class: 'product-row' }
    }

    return {
      punchoutForm,
      loading,
      headers,
      dialog,
      showPunchoutPopup,
      cancel,
      formatPurchasePrice,
      getRowProps
    }
  }
})
</script>
