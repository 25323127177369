import { VAutoCompleteGroupDivider } from '@/types'
import { OrderSearchModel, MediumProduct } from '@/generatedTypes'

type InstantSearchTitle = { type: InstantSearchType; key: string }

enum InstantSearchType {
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER'
}

type InstantSearchItem =
  | MediumProduct
  | OrderSearchModel
  | VAutoCompleteGroupDivider
  | InstantSearchTitle

export { InstantSearchItem, InstantSearchTitle, InstantSearchType }
