import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/product/api'
import { FullProduct, ProductPriceUpdate } from '@/generatedTypes'
import { defineStore } from 'pinia'
import useBundleItems from '@/modules/product/useBundleItems'
import useProductQuantity from '@/modules/product/useProductQuantity'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import { trackEvent } from '@/modules/tracking/useTracking'
import ProductPageEvent from '@/modules/tracking/events/productPage'

interface ProductState {
  product: FullProduct | undefined
  loading: boolean
}

const initialState = (): ProductState => {
  return {
    product: undefined,
    loading: false
  }
}

const useProduct = defineStore('product', {
  state: () => initialState(),
  actions: {
    async loadProduct(id: number) {
      this.$reset()
      this.loading = true
      useProductQuantity().$reset()
      useBundleItems().$reset()

      return api
        .loadProduct(id)
        .then(({ data }) => {
          this.product = data
          trackEvent(ProductPageEvent.PageView(data.title))
          useRealtimePrices().loadRealtimePrices({ productId: id, scale: 1 })
          if (this.product.isBundle) {
            useBundleItems().loadBundleItems(id)
          }
          useProductQuantity().initializeQuantity(this.product.price)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              appearance: 'CUSTOM',
              customHandler: 'CategoryLayout'
            },
            {
              errorCode: 404,
              logError: false,
              customMessageKey: 'error.productNotFound'
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateProductPrices(update: ProductPriceUpdate) {
      if (this.product) {
        this.product.price = update.price
        this.product.stock = update.stock
        this.product.suppliers.forEach((supplier) => {
          const supplierUpdate = update.suppliers.find(
            (supplierUpdate) => supplier.id === supplierUpdate.id
          )
          if (supplierUpdate) {
            supplier.price = supplierUpdate.price
            supplier.stock = supplierUpdate.stock
            supplier.scales = supplierUpdate.scales
          }
        })
      }
    }
  }
})

export default useProduct
