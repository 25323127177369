<template>
  <v-dialog :model-value="dialog" scrollable :width="500" @keydown.esc="toggleDialog">
    <template #activator="{ props }">
      <sf-icon-button
        v-bind="props"
        id="quote-button"
        :icon="mdiHandshake"
        :color="isInQuote ? 'green' : 'primary'"
        @click="toggleDialog"
      />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('quotes.createRequest') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="d-flex flex-column">
        <template v-if="quotesIncludingProduct.length > 0">
          <div
            class="d-flex flex-column pb-1 overflow-y-auto overflow-x-hidden scrollbar"
            style="max-height: 128px"
          >
            <div
              v-for="quoteIncludingProduct in quotesIncludingProduct"
              :key="quoteIncludingProduct.id"
              class="d-flex align-center"
            >
              <v-icon :icon="mdiCheck" color="green" class="mr-1" />
              <sf-text class="text-truncate">
                {{ quoteIncludingProduct.name }}
              </sf-text>
            </div>
          </div>
          <v-divider class="my-1" />
        </template>
        <sf-text class="mb-1">
          {{ $t('quotes.addProductToExistingQuote') }}
        </sf-text>
        <v-autocomplete
          v-model="selectedQuotes"
          color="primary"
          variant="outlined"
          density="compact"
          clearable
          chips
          closable-chips
          multiple
          hide-details
          :items="quotesExcludingProduct"
          item-title="name"
          item-value="id"
          return-object
          :no-data-text="$t('quotes.noQuoteRequestsFound')"
        >
        </v-autocomplete>
        <sf-text class="my-1">
          {{ $t('quotes.addProductToNewQuote') }}
        </sf-text>
        <sf-text-field v-model="newQuote" clearable hide-details autocomplete="off" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button
          color="primary"
          :disabled="(!selectedQuotes || selectedQuotes.length === 0) && !newQuote"
          @click="addProductToQuoteRequest"
        >
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import { defineComponent, PropType, ref, watchEffect } from 'vue'
import { QuoteRequestForProduct } from '@/generatedTypes'
import { Event } from '@/modules/tracking/types'
import { trackEvent } from '@/modules/tracking/useTracking'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { storeToRefs } from 'pinia'
import useAddToQuote from '@/modules/quote/useAddToQuote'
import { mdiHandshake, mdiCheck } from '@mdi/js'

export default defineComponent({
  name: 'QuoteButton',
  components: { SfText, SfHeading, SfTextField, SfTextButton, SfIconButton },
  props: {
    productId: {
      type: Number,
      required: true
    },
    addToQuoteEvent: {
      type: Object as PropType<Event>,
      default: undefined
    },
    createQuoteEvent: {
      type: Object as PropType<Event>,
      default: undefined
    }
  },
  setup(props) {
    const dialog = ref(false)
    const search = ref('')
    const newQuote = ref<string | undefined>(undefined)
    const selectedQuotes = ref<QuoteRequestForProduct[]>([])
    const { quotesIncludingProduct, quotesExcludingProduct, isInQuote } =
      storeToRefs(useAddToQuote())

    watchEffect(() => {
      useAddToQuote().isProductInQuoteRequest(props.productId.toString())
    })

    const toggleDialog = () => {
      if (!dialog.value) {
        useAddToQuote().getDraftQuotes(props.productId.toString())
        selectedQuotes.value = []
        newQuote.value = undefined
      }
      dialog.value = !dialog.value
    }
    const addProductToQuoteRequest = () => {
      if (newQuote.value) {
        if (typeof newQuote.value === 'string') {
          useAddToQuote().addToNewQuoteRequest(newQuote.value, props.productId)
        }
        if (props.createQuoteEvent) {
          trackEvent(props.createQuoteEvent.withProduct(props.productId))
        }
      }
      if (selectedQuotes.value) {
        useAddToQuote().addToQuoteRequests(selectedQuotes.value, props.productId)
        if (props.addToQuoteEvent) {
          trackEvent(props.addToQuoteEvent.withProduct(props.productId))
        }
      }
      dialog.value = !dialog.value
    }

    return {
      mdiHandshake,
      mdiCheck,
      isInQuote,
      quotesExcludingProduct,
      quotesIncludingProduct,
      dialog,
      search,
      newQuote,
      selectedQuotes,
      addProductToQuoteRequest,
      toggleDialog
    }
  }
})
</script>
