<template>
  <v-row>
    <v-col cols="12">
      <order-loading v-if="loading" />

      <template v-if="!loading && order">
        <div
          ref="order-title"
          class="d-flex justify-space-between"
          :style="!$vuetify.display.mobile ? 'height: 36px' : ''"
        >
          <div class="d-flex flex-row">
            <show-categories-button class="mr-1" />

            <div class="d-flex flex-wrap flex-lg-nowrap align-center">
              <sf-title
                class="mr-2"
                :class="[
                  {
                    'text-disabled': order.isArchived,
                    'text-truncate': !$vuetify.display.mobile
                  }
                ]"
              >
                {{
                  order.distributorNames.length > 0
                    ? $t('orderBy', [formatDistributors(order.distributorNames)])
                    : $t('order.order') + ' ' + order.orderNumber
                }}
              </sf-title>
              <div class="d-flex flex-shrink-0 align-center">
                <sf-color-text
                  ref="status"
                  :color="getStatusColor(order.orderStatus)"
                  class="text-center"
                >
                  {{ $t('orderStatus.' + order.orderStatus) }}
                </sf-color-text>
                <sf-archived-chip v-if="order.isArchived" class="ml-2" />
              </div>
            </div>
          </div>

          <order-menu :order="order" />
        </div>

        <v-tabs bg-color="transparent" color="primary" class="mt-1">
          <v-tab :to="{ name: 'OrderDetails', params: { id: order.id } }">{{
            $t('order.details')
          }}</v-tab>
          <v-tab :to="{ name: 'OrderDocuments', params: { id: order.id } }">{{
            $t('order.documents')
          }}</v-tab>
        </v-tabs>

        <v-divider />

        <router-view />
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import SfArchivedChip from '@/components/chips/SfArchivedChip.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import useAddress from '@/modules/address/useAddress'
import OrderLoading from '@/modules/order/components/OrderLoading.vue'
import OrderMenu from '@/modules/order/components/OrderMenu.vue'
import { formatDistributors, getStatusColor } from '@/modules/order/helpers'
import useOrder from '@/modules/order/useOrder'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'OrderPage',
  components: {
    OrderLoading,
    OrderMenu,
    ShowCategoriesButton,
    SfTitle,
    SfColorText,
    SfArchivedChip
  },
  setup() {
    const { t } = useI18n()
    const currentRoute = useRoute()

    watch(currentRoute, (from, to) => {
      if (from.params.id != to.params.id) {
        useOrder().getOrder(currentRoute.params.id as string)
      }
    })

    useAddress().getAddresses()
    useOrder().getOrder(currentRoute.params.id as string)

    const { order, loading } = storeToRefs(useOrder())

    const pageTitle = computed(() =>
      order.value ? `${t('order.order')} ${order.value.orderNumber}` : `${t('order.order')}`
    )
    useTitle(pageTitle)

    return {
      order,
      loading,
      getStatusColor,
      formatDistributors
    }
  }
})
</script>
