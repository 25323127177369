import {
  PathsStorefrontCollectionsActionsToCartPostResponses200ContentApplicationJson,
  PathsStorefrontOrdersGetParametersQuerySort,
  OrderSumInformation,
  OrderItem,
  OrderStatus,
  ProposeOrderPostDTO,
  Money,
  ShortUser
} from '@/generatedTypes'

const ORDER_NUMBER_MAX_LENGTH = 250

type OrderPreviewColumns = {
  ARRIVED: OrderPreviewColumn
  PROCESSING: OrderPreviewColumn
  ADVISED: OrderPreviewColumn
  INVOICED: OrderPreviewColumn
  ERROR: OrderPreviewColumn
}

type OrderPreviewColumnsKey = keyof OrderPreviewColumns

type OrderPreview = {
  id: string
  initiatorUser: ShortUser
  orderSumInformation: OrderSumInformation
  distributorNames: string[]
  orderNumber: string
  orderStatus: OrderStatus
  items: OrderItem[]
  shippingCost: Money
  sentDate?: string
}

type OrderPreviewColumn = {
  orderStatus: OrderStatus
  orderPreviews: OrderPreview[]
  count: number
  pages: number
  loading: boolean
  loadingNextPage: boolean
}

type PreparedOrder = Omit<ProposeOrderPostDTO, 'timestamp'>

enum SortDirection {
  ASC,
  DESC,
  NONE
}

enum MobexPaymentMethod {
  BILL,
  MOBEX360
}

enum OrdersView {
  LIST = 'LIST',
  GRID = 'GRID',
  APPROVALS = 'APPROVALS'
}

export {
  PathsStorefrontCollectionsActionsToCartPostResponses200ContentApplicationJson as AddToCartSuccess,
  MobexPaymentMethod,
  ORDER_NUMBER_MAX_LENGTH,
  OrderPreviewColumn,
  OrderPreviewColumns,
  OrderPreviewColumnsKey,
  PathsStorefrontOrdersGetParametersQuerySort as OrderSortOrder,
  OrdersView,
  PreparedOrder,
  SortDirection
}
