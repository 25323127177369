<template>
  <v-card class="py-1 px-2 mb-1 pw-request-position">
    <v-row no-gutters>
      <v-col cols="auto" class="d-flex align-center mr-2">
        <product-image-gallery
          :images="quoteItem.productImageUrls"
          :size="100"
          :hide-arrows="true"
          :hover-effect="true"
        />
      </v-col>
      <v-col class="d-flex justify-center">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center mr-1 mr-md-2">
            <router-link
              v-if="!isQuotesPortal && quoteItem.productId"
              :to="{
                name: 'Product',
                params: { id: quoteItem.productId }
              }"
              class="text-body-1 text-decoration-none text-primary"
              v-html="quoteItem.productTitle"
            />
            <sf-debounced-text-field
              v-else-if="editable && !quoteItem.productId"
              v-model="label"
              hide-details
              variant="underlined"
              density="compact"
              class="align-center mt-2 max-w-600"
              autocomplete="off"
              @debounce="onChangeTitle"
            />
            <span v-else ref="title" v-html="quoteItem.productTitle" />
          </v-col>
          <v-col class="hidden-md-and-up" cols="12">
            <v-divider class="my-1" />
          </v-col>
          <v-col cols="12" md="auto" align-self="center">
            <v-row no-gutters justify="end">
              <v-col cols="auto" class="d-flex justify-center align-center mr-md-2">
                <sf-heading v-if="isQuotesPortal" class="mr-1"> x </sf-heading>
                <quantity-stepper
                  v-if="editable"
                  :class="[{ 'mr-1': !isQuotesPortal }]"
                  :quantity="quantity"
                  @change-quantity="onChangeQuantity"
                />
                <sf-heading v-else ref="quantity">{{ quoteItem.quantity }}</sf-heading>
                <sf-heading v-if="!isQuotesPortal">x</sf-heading>
              </v-col>
              <v-col
                v-if="!isQuotesPortal"
                cols="auto"
                style="width: 100px"
                class="d-flex flex-column justify-center align-center align-md-end ml-3 ml-md-0"
              >
                <div v-if="quoteItem.price" class="d-flex align-center">
                  <sf-heading ref="price">
                    {{ formatMoney(quoteItem.price.resellPrice, true, true) }}
                  </sf-heading>
                </div>
              </v-col>
              <v-col
                v-if="editable"
                cols="auto"
                style="width: 36px"
                class="d-flex justify-center align-center ml-md-2"
              >
                <sf-icon-button color="" :icon="mdiTrashCanOutline" @click="removeProduct" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfDebouncedTextField from '@/components/input/SfDebouncedTextField.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { isQuotesPortal } from '@/helpers'
import ProductImageGallery from '@/modules/product/components/ProductImageGallery.vue'
import { formatMoney } from '@/modules/product/helpers'
import { QuoteItem } from '@/generatedTypes'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import { defineComponent, PropType, ref } from 'vue'
import { mdiTrashCanOutline } from '@mdi/js'

export default defineComponent({
  name: 'RequestPosition',
  components: {
    SfDebouncedTextField,
    SfIconButton,
    ProductImageGallery,
    QuantityStepper,
    SfHeading
  },
  props: {
    quoteItem: {
      type: Object as PropType<QuoteItem>,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const label = ref(props.quoteItem.productTitle)
    const quantity = ref(props.quoteItem.quantity)

    const onChangeQuantity = (newQuantity: number) => {
      quantity.value = newQuantity
      useQuoteRequest().updateRequestItemQuantity(
        props.quoteItem.id,
        newQuantity,
        props.quoteItem.productTitle
      )
    }

    const onChangeTitle = () => {
      useQuoteRequest().updateLabel(props.quoteItem.id, label.value)
    }

    const removeProduct = () => {
      useQuoteRequest().deleteRequestItem(props.quoteItem.id)
    }

    return {
      quantity,
      label,
      isQuotesPortal,
      removeProduct,
      formatMoney,
      onChangeQuantity,
      onChangeTitle,
      mdiTrashCanOutline
    }
  }
})
</script>
